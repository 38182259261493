import { useCallback, useEffect, useState } from 'react';
import { useJobStatus } from '../api/getJobStatus';
import { queryClient } from '../lib/react-query';
import useEnqueueSnackbar from 'hooks/useEnqueueSnackbar';

const useJobStatusCheck = () => {
  const { onShowErrorAlert, onShowAlert } = useEnqueueSnackbar();
  const [jobId, setJobId] = useState<string | null>(null);
  const [refetchInterval, setRefetchInterval] = useState<number | null>(null);

  const { data, isLoading, isError, error, isFetching, isPending, isRefetching } =
    useJobStatus({
      params: { job_id: jobId },
      config: {
        enabled: !!jobId,
        refetchInterval: refetchInterval ?? undefined,
      },
    });

  const clearJobStatus = () => {
    setRefetchInterval(null);
    setJobId(null);
    queryClient.removeQueries({
      queryKey: ['api', 'v1', 'job_status'],
    });
  };

  useEffect(() => {
    if (error) {
      onShowErrorAlert((error as { cause: { error: string } })?.cause.error ?? 'Failed to check job status');
    }
    if (isError) {
      setJobId(null);
    }
  }, [onShowErrorAlert, error, isError]);

  useEffect(() => {
    if (jobId) {
      setRefetchInterval(15000);
    }
  }, [jobId]);

  useEffect(() => {
    if (data) {
      switch (data.status) {
        case 'queued':
        case 'scheduled':
        case 'started':
          // Continue polling
          break;
        case 'finished':
          console.log('Job finished:', data.result);
          onShowAlert('Job finished');
          setRefetchInterval(null); // Clear interval, but not job id to retain cached data
          break;
        case 'failed':
          console.error('Job failed:', data.error);
          onShowErrorAlert('Job failed');
          setJobId(null); // Stop polling
          setRefetchInterval(null); // Clear interval, but not job id to retain cached data
          break;
        default:
          console.log('Unknown job status:', data.status);
      }
    }
  }, [data, onShowAlert, onShowErrorAlert]);

  const runJubStatusCheck = useCallback(
    (jobId: string) => {
      setJobId(jobId);
    },
    [],
  );

  return {
    isLoading:
      isLoading || isRefetching || (data && data.status !== 'finished'),
    isRefetching,
    isPending,
    isError,
    isFetching,
    isFinished: data && data.status === 'finished',
    data,
    runJubStatusCheck,
    clearJobStatus,
  };
};

export default useJobStatusCheck;