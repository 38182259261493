import { useCallback, useMemo } from 'react';
import { Button, CircularProgress, useTheme } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import AlertMessage from 'components/common/AlertMessage';

interface UseEnqueueSnackbarProps {
  onUndo?: () => void;
  delay?: number;
}

interface EnqueueAlertProps {
  delayAlert?: number;
  isPending?: boolean;
  backgroundColor: string;
  color: string;
  onUndo?: () => void;
}

const useEnqueueSnackbar = ({ delay }: Pick<UseEnqueueSnackbarProps, 'delay'> = {}) => {
  const theme = useTheme();

  const getSnackbarStyle = useMemo(() => (backgroundColor: string, color: string) => ({
    paddingBottom: 0,
    paddingTop: 0,
    backgroundColor,
    color,
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    marginLeft: 0,
  }), [theme.shape.borderRadius]);

  const enqueueAlert = useCallback(
    (message: string, options: EnqueueAlertProps) => {
      enqueueSnackbar(<AlertMessage message={message} />, {
        autoHideDuration: (options?.delayAlert ?? delay) ?? 3500,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        style: getSnackbarStyle(options.backgroundColor, options.color),
        action: options?.isPending ? (
          <CircularProgress size={24} color={'inherit'} />
        ) : options?.onUndo ? (
          <Button
            sx={(t) => ({ color: 'white', fontSize: t.typography.body2.fontSize })}
            onClick={() => {
              closeSnackbar();
              options?.onUndo?.();
            }}
          >
            Undo
          </Button>
        ) : null,
      });
    },
    [getSnackbarStyle, delay],
  );

  return {
    onShowAlert: (
      message: string,
      { isPending, onUndo, delayAlert }: Pick<EnqueueAlertProps, 'onUndo' | 'isPending' | 'delayAlert'> = {},
    ) => {
      enqueueAlert(message, {
        backgroundColor: 'rgba(237, 247, 237, 1)',
        color: 'rgba(30, 70, 32, 1)',
        onUndo,
        isPending,
        delayAlert,
      });
    },
    onShowInfoAlert: (
      message: string,
      { isPending, onUndo, delayAlert }: Pick<EnqueueAlertProps, 'onUndo' | 'isPending' | 'delayAlert'> = {},
    ) => {
      enqueueAlert(message, {
        backgroundColor: 'rgba(237, 238, 247, 1)',
        color: theme.palette.text.primary,
        onUndo,
        isPending,
        delayAlert,
      });
    },
    onShowErrorAlert: (message: string, {
      delayAlert,
      onUndo,
      isPending,
    }: Pick<EnqueueAlertProps, 'onUndo' | 'isPending' | 'delayAlert'> = {}) => {
      enqueueAlert(message, {
        backgroundColor: 'rgba(203, 46, 61, 1)',
        color: 'white',
        delayAlert,
        isPending,
        onUndo,
      });
    },
  };
};

export default useEnqueueSnackbar;
