import { useFormContext } from 'react-hook-form';
import { useCreatives } from '../api/getCreatives';
import type { CreativeEntry } from '../../../types/shared';
import { queryClient } from '../../../lib/react-query';
import queryKeys from '../api/queryKeys';
import { useEffect, useState } from 'react';
import { isNull, isNumber } from 'lodash-es';
import type { PackEntry } from '../../facebook/types';

interface Params {
  ad_type?: string;
  network?: string;
}

interface UseRowsCreativesReturn {
  rows?: CreativeEntry[];
  selectedRows?: CreativeEntry[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  error?: Record<string, any>;
}

const useRowsCreatives = ({ params }: { params?: Params } = {}): UseRowsCreativesReturn => {
  const { watch, setValue } = useFormContext();
  const app = watch('app') as string;
  const adType = watch('adType') as string;
  const network = watch('network') as string;
  const filterCreativeByName = watch('filterCreativeByName') as string;
  const selectedCreatives = watch('selectedCreatives') as string[];
  const packsWithCreatives = watch('packsWithCreatives') as PackEntry[];
  const activePacksWithCreatives = watch('activePacksWithCreatives') as number | null;

  const { data: rows, isLoading, isSuccess, isError, error } = useCreatives({
    config: {
      enabled: !!adType,
    },
    params: {
      app,
      query: filterCreativeByName ?? '',
      ad_type: params?.ad_type ?? adType,
      network: params?.network ?? network,
    },
  });

  const cachedCreativesData =
    queryClient
      .getQueryCache()
      .findAll({ queryKey: queryKeys.allCreatives })
      .flatMap(query => query.state.data as CreativeEntry[]);

  const findByIdInCache = (id: string) => cachedCreativesData.find(c => c?.id === id) ?? {};
  const filterById = (item: CreativeEntry) => !rows?.some(c => c.id === item.id);

  const packIds = isNumber(activePacksWithCreatives) ? (packsWithCreatives?.[activePacksWithCreatives]?.creatives as string[] ?? []) : [];
  const list = [...new Set([...packIds, ...selectedCreatives])];
  const selectedRows = list.map(findByIdInCache) as CreativeEntry[];

  const [stateRows, setStateRows] = useState<CreativeEntry[]>([]);

  useEffect(() => {
    // it is important to keep only the selected ones that are not repeated in the main list of rows
    const keepUniqSelectedRows = selectedRows.filter(filterById);
    const actualRows = [...keepUniqSelectedRows, ...(rows ?? [])];
    setStateRows(actualRows);
  }, [filterById, rows]);

  useEffect(() => {
    if (!isNull(activePacksWithCreatives)) {
      const selectedPackRows = packIds?.map(findByIdInCache) as CreativeEntry[];
      const keepUniqSelectedRows = selectedPackRows.filter(filterById);

      const actualRows = [...keepUniqSelectedRows, ...(rows ?? [])];
      setStateRows(actualRows);

      setValue('selectedCreatives', packIds ?? []);
    }
  }, [activePacksWithCreatives]);

  useEffect(() => {
    if (isLoading) setStateRows([]);
  }, [isLoading]);


  return {
    rows: stateRows,
    selectedRows,
    isLoading,
    isSuccess,
    isError,
    error: error ?? {},
  };
};

export default useRowsCreatives;