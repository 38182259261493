import { apiClient } from '../../../lib/api/apiClient';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import type { SynchronousQueryConfig } from '../../../lib/react-query';
import queryKeys from './queryKeys';

export interface AdSettingsRequestBody {
  app: string;
  platform: string;
  ad_sets: { account_id: string; name: string; id: string; campaign_id: string; }[];
}

export interface AdSettingPageEntry {
  name: string;
  is_default: boolean;
  id: number;
}

export interface AdSettingTextEntry {
  name: string;
  is_default: boolean;
  id: number;
}

export interface AdSettingEntry {
  name: string;
  pages: AdSettingPageEntry[];
  texts: AdSettingTextEntry[];
}

const getAdSettings = async (body: AdSettingsRequestBody): Promise<AdSettingEntry[]> => {
  const res = await apiClient.post<AdSettingEntry[]>(
    `/api/v1/facebook_manager/get_ad_settings/`,
    body,
  );

  if (isAxiosError(res)) {
    const error = new Error('Get Ad Settings Failed');
    error.cause = res.response?.data;
    return Promise.reject(error);
  }
  return res.data;
};

interface UseAdSettings<TData> {
  config?: SynchronousQueryConfig<typeof getAdSettings, TData>;
  params: AdSettingsRequestBody;
}

export const useAdSettings = <TData = AdSettingEntry[]>({ params: body, config }: UseAdSettings<TData>) => {
  return useQuery({
    ...config,
    queryFn: () => getAdSettings(body),
    queryKey: [
      ...queryKeys.adSettings,
      ...Object.values(body).join(','),
    ],
    retry: false,
  });
};

export default useAdSettings;