import type { Theme } from '@mui/material';
import { Box, Typography } from '@mui/material';
import type { FC, ReactNode } from 'react';
import type { SxProps } from '@mui/system/styleFunctionSx';

interface FormContainerProps {
  children: ReactNode;
  rightSideElement?: ReactNode;
  title: string;
  error?: string | string[];
  sx?: SxProps<Theme>;
}

const FormContainer: FC<FormContainerProps> = ({ title, sx, rightSideElement, children }) => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: 1,
      justifyContent: 'start',
      width: '100%',
    }}>
      <Box sx={{
        display: 'flex',
        gap: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: rightSideElement ? 'space-between' : 'start',
      }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {rightSideElement && <Box sx={{
          display: 'flex',
          gap: 1,
          flexDirection: 'row',
          alignItems: 'start',
        }}>
          {rightSideElement}
        </Box>}
      </Box>
      <Box sx={{
        position: 'relative',
        display: 'flex',
        flex: 1,
        width: '100%',
        ...sx,
      }}>
        {children}
      </Box>
    </Box>
  );
};

export default FormContainer;