import { useBlocker } from 'react-router';
import { useState } from 'react';

const useRouteBlocker = ({ callback }: { callback?: () => void } = {}) => {
  const [nextPathname, setNextPathname] = useState('');
  const routeBlocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname !== nextLocation.pathname && routeBlocker.state === 'blocked') {
      setNextPathname(currentLocation.pathname);
      callback && callback();
      return true; // Prevent navigation
    }
    return false; // Allow navigation
  });

  const onBlockRoute = () => {
    routeBlocker.state = 'blocked';
  };

  const onUnblockRoute = () => {
    routeBlocker.state = 'unblocked';
  };

  const onProceedingRoute = () => {
    routeBlocker.state = 'proceeding';
  };

  return {
    routeBlocker,
    nextPathname,
    onBlockRoute,
    onUnblockRoute,
    onProceedingRoute,
  };
};

export default useRouteBlocker;
