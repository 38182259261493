export const APP_LIST = [
  {
    name: 'Muscle Booster',
    code: 'MB',
  },
  {
    name: 'YogaGo',
    code: 'YG',
  },
  {
    name: 'WalkFit',
    code: 'WF',
  },
];

export const WEB_PLATFORM = 'WEB';
export const MOBILE_PLATFORM = 'MOBILE';
export const APP_PLATFORMS = [
  {
    name: 'Web',
    code: 'WEB',
  },
  {
    name: 'Mobile',
    code: 'MOBILE',
  },
];

export const MARKETING_PROCESS = [
  {
    name: 'Inbox',
    code: 'inbox',
  },
  {
    name: 'Testing',
    code: 'testing',
  },
  {
    name: 'Scaling',
    code: 'scaling',
  },
];

export const TIKTOK_ADS_AD_LIST = [
  {
    name: 'Smart Ad',
    code: 'SMART_AD',
  },
  {
    name: 'Single Video Ad',
    code: 'SINGLE_VIDEO',
  },
];

export const AGE_RANGE_ITEMS = [
  { value: 'AGE_13_17', label: '13-17' },
  { value: 'AGE_18_24', label: '18-24' },
  { value: 'AGE_25_34', label: '25-34' },
  { value: 'AGE_35_44', label: '35-44' },
  { value: 'AGE_45_54', label: '45-54' },
  { value: 'AGE_55_100', label: '55+' },
];

export const SPENDING_POWER_ITEMS = [
  { value: 'ALL', label: 'All SP' },
  { value: 'HIGH', label: 'High SP' },
];

export const GENDER_ITEMS = [
  { value: 'GENDER_FEMALE', label: 'Female' },
  { value: 'GENDER_MALE', label: 'Male' },
];

export const TEAM_ITEMS = [
  { value: 'MM', label: 'MM' },
  { value: 'UA', label: 'UA' },
];

export const DEFAULT_TARGET_CPA = '65.00';
export const DEFAULT_TARGET_ROAS = '1.00';

export const CPA_VALUE = 'tCPA';
export const ROAS_VALUE = 'ROAS';
export const LOWEST_VALUE = 'LC';
export const HIGHEST_VALUE = 'HV';

export const BID_STRATEGIES_LIST = [
  { name: 'Highest Value', value: HIGHEST_VALUE },
  { name: 'Lowest Cost', value: LOWEST_VALUE },
  { name: 'ROAS', value: ROAS_VALUE },
  { name: 'CPA', value: CPA_VALUE },
];

export const BID_STRATEGIES = {
  [HIGHEST_VALUE]: {
    bid_type: 'BID_TYPE_NO_BID',
    deep_bid_type: 'VO_HIGHEST_VALUE',
    select_bid_type: HIGHEST_VALUE, // bid_strategy
    input_value: null, // target_cpa
    optimization_goal: 'VALUE',
  },
  [LOWEST_VALUE]: {
    bid_type: 'BID_TYPE_NO_BID',
    deep_bid_type: 'DEFAULT',
    select_bid_type: LOWEST_VALUE, // bid_strategy
    input_value: null, // target_cpa
    optimization_goal: 'CONVERT',
  },
  [CPA_VALUE]: {
    bid_type: 'BID_TYPE_CUSTOM',
    deep_bid_type: 'PACING',
    select_bid_type: CPA_VALUE, // bid_strategy
    input_value: DEFAULT_TARGET_CPA, // target_cpa
    optimization_goal: 'CONVERT',
  },
  [ROAS_VALUE]: {
    bid_type: 'BID_TYPE_NO_BID',
    deep_bid_type: 'VO_MIN_ROAS',
    select_bid_type: ROAS_VALUE, // bid_strategy
    input_value: DEFAULT_TARGET_ROAS, // target_roas - min/max value 0.01-1,000
    optimization_goal: 'VALUE',
  },
};
