import type { ComponentProps, FC } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField as MuiTextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

interface TextFieldProps {
  name: string;
  label?: string;
  inputLabel?: string;
  placeholder?: string;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  restProps?: ComponentProps<typeof MuiTextField>;
  maxLength?: number;
}

const TextField: FC<TextFieldProps> =
  ({
     name,
     label,
     inputLabel,
     placeholder,
     helperText,
     required,
     disabled,
     handleChange,
     maxLength,
     ...restProps
   }) => {
    const { control, formState, trigger } = useFormContext<FieldValues>();
    const error = formState.errors[name];

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { value, onChange } = field;
          return (
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
              {label && !inputLabel && (
                <Typography variant="subtitle2" mb={1}>
                  {label}
                  {required && '*'}
                </Typography>
              )}
              <MuiTextField
                {...field}
                value={(value as string) ?? ''}
                fullWidth
                label={label ?? inputLabel}
                error={!!error}
                disabled={disabled}
                placeholder={placeholder}
                helperText={helperText}
                {...restProps}
                variant="outlined"
                onChange={async (event) => {
                  onChange(event);
                  await trigger(name);
                  handleChange && handleChange(event);
                }}
                inputProps={{ maxLength: maxLength }}
              />
              {error?.message && (
                <Typography
                  variant="subtitle1"
                  fontSize={12}
                  sx={{ mt: '3px' }}
                  color="error"
                >
                  {error.message as string}
                </Typography>
              )}
            </Box>
          );
        }}
      />
    );
  };

export default TextField;