import type { SelectChangeEvent} from '@mui/material';
import { Grid, Typography } from '@mui/material';
import SelectApplications from '../../../components/common/SelectApplications';
import SelectMarketingProcess from '../../../components/common/SelectMarketingProcess';
import SelectPlatform from '../../../components/common/SelectPlatform';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import type { MarketingProcess } from "../../../types/shared";

const AppPlatformForm: FC = () => {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Choose App & Platform
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectApplications />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectPlatform />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectMarketingProcess />
        </Grid>
      </Grid>
    </>
  );
};
export default AppPlatformForm;