import useEnqueueSnackbar from '../../../hooks/useEnqueueSnackbar';
import { useFormContext } from 'react-hook-form';
import type { PackEntry } from '../types';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';
import { Box } from '@mui/system';
import { Chip, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { bindDialog, usePopupState } from 'material-ui-popup-state/hooks';
import { bindTrigger } from 'material-ui-popup-state';
import TextField from '../../../components/common/TextField';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

export const PacksWithCreatives = () => {
  const { onShowErrorAlert } = useEnqueueSnackbar();
  const { watch, setValue, trigger } = useFormContext();

  const packsWithCreatives = watch('packsWithCreatives') as PackEntry[];
  const activePacksWithCreatives = watch('activePacksWithCreatives') as number;

  const marketingProcess = watch('marketingProcess') as string;
  const isMarketingProcessInbox = marketingProcess?.includes('inbox');

  const handleDeletePack = useCallback((i: number) => {
    const clonePacksWithCreatives = [...packsWithCreatives];
    const cloneActivePacksWithCreatives = Number(activePacksWithCreatives);

    const splicePacksWithCreatives = clonePacksWithCreatives?.filter((_, index) => index !== i);
    setValue('packsWithCreatives', splicePacksWithCreatives);
    setValue('activePacksWithCreatives', 0);

    onShowErrorAlert('Pack deleted', {
      delayAlert: 3500,
      onUndo: () => {
        setValue('selectedCreatives', clonePacksWithCreatives[cloneActivePacksWithCreatives]);
        setValue('packsWithCreatives', clonePacksWithCreatives);
        setValue('activePacksWithCreatives', cloneActivePacksWithCreatives);
      },
    });

    void trigger('packsWithCreatives');
  }, [onShowErrorAlert, activePacksWithCreatives, packsWithCreatives, setValue, trigger]);

  const handleSelectPack = useCallback((i: number) => {
    setValue('filterCreativeByName', '');
    setValue('activePacksWithCreatives', i);
  }, [setValue]);

  if (isMarketingProcessInbox) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
      {packsWithCreatives.map(({ tag, creatives }, i) => (
        <Chip
          size={'medium'}
          key={`${i}.${creatives.join('')}`}
          label={
            <Tooltip
              title={tag.length > 38 ? `#${i + 1} ${tag ? tag : 'Pack'} (${creatives.length})` : null}
              placement={'top'}>
              <Box sx={{
                display: 'flex',
              }}>
                <Typography
                  component={'span'}
                  color={'inherit'}
                  sx={{
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    maxWidth: 180,
                    overflow: 'hidden',
                  }}>
                  {`#${i + 1} ${tag ? tag : 'Pack'} (${creatives.length})`}
                </Typography>
              </Box>
            </Tooltip>
          }
          onClick={() => {
            handleSelectPack(i);
          }}
          onDelete={packsWithCreatives.length > 1 ? () => {
            handleDeletePack(i);
          } : undefined}
          color={String(activePacksWithCreatives) === String(i) ? 'primary' : 'default'}
        />))}
    </Box>
  );
};

export const AddPackButton = () => {
  const { onShowAlert } = useEnqueueSnackbar();
  const { watch, setValue, trigger } = useFormContext();
  const selectedCreatives = watch('selectedCreatives') as string[];
  const packsWithCreatives = watch('packsWithCreatives') as PackEntry[];
  const marketingProcess = watch('marketingProcess') as string;

  const isInbox = marketingProcess.includes('inbox');
  const isSomePackEmpty = packsWithCreatives.some(pack => !pack.creatives.length);
  const isAddPackAvailable = !isSomePackEmpty && selectedCreatives?.length && !isInbox;

  const handleAddPack = useCallback(() => {
    const newPacksWithCreatives: PackEntry[] = [...packsWithCreatives, { tag: '', creatives: [] }];
    setValue('packsWithCreatives', newPacksWithCreatives);
    setValue('activePacksWithCreatives', newPacksWithCreatives.length - 1);

    onShowAlert('Pack added', {
      delayAlert: 3500,
    });

    void trigger('packsWithCreatives');
  }, [onShowAlert, setValue, packsWithCreatives, trigger]);

  return (
    <Tooltip
      title={(isAddPackAvailable || isInbox) ? '' : 'Select creatives to add a new pack'}
      placement={'top'}>
      <Box>
        <Button
          disabled={!isAddPackAvailable}
          variant="outlined"
          color="primary"
          onClick={handleAddPack}>Add pack</Button>
      </Box>
    </Tooltip>

  );
};

export const AddPackTagButton = () => {
  const popupState = usePopupState({ variant: 'dialog', popupId: 'addPackTag' });
  const { watch, setValue, getValues } = useFormContext();
  const packsWithCreatives = watch('packsWithCreatives') as PackEntry[];
  const activePacksWithCreatives = watch('activePacksWithCreatives') as number;
  const activePacksTag = packsWithCreatives?.[activePacksWithCreatives]?.tag;

  const handleCancel = useCallback(() => {
    setValue(`activePackTag`, '');
    popupState.close();
  }, [setValue, popupState]);

  const handleSave = useCallback(() => {
    const activePackTag = getValues(`activePackTag`) as string;
    setValue(`packsWithCreatives[${activePacksWithCreatives}].tag`, activePackTag);
    popupState.close();
  }, [setValue, getValues, popupState, activePacksWithCreatives]);

  const handleOpenDialog = useCallback(() => {
    const currentValue = getValues(`packsWithCreatives[${activePacksWithCreatives}].tag`) as string;
    setValue('activePackTag', currentValue);
  }, [setValue, getValues, activePacksWithCreatives]);

  return <>
    <Dialog {...bindDialog(popupState)} PaperProps={{ sx: { maxWidth: 440, minHeight: 180, width: '90%', py: 1 } }}>
      <DialogTitle>Campaign tag</DialogTitle>
      <DialogContent sx={{ px: 2 }}>
        <TextField name={`activePackTag`} maxLength={64} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
        <Button onClick={handleCancel} variant="text" color="error">Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
    <Button
      disabled={!packsWithCreatives.length || activePacksWithCreatives === null}
      size="small"
      variant={activePacksWithCreatives === null ? 'outlined' : 'contained'}
      color="infoFill"
      sx={{
        '&': {
          display: 'flex',
          gap: 1 / 2,
          alignItems: 'center',
          whiteSpace: 'nowrap',
          textTransform: 'initial',
          pl: 1,
          minWidth: 20,
          maxWidth: 160,
        },
      }}
      {...bindTrigger(popupState)}
      onClick={(e: MouseEvent) => {
        bindTrigger(popupState).onClick(e);
        handleOpenDialog();
      }}>
      {activePacksTag &&
        <LocalOfferIcon sx={t => ({ fontSize: t.typography.h4.fontSize, color: t.palette.text.secondary })} />}
      <Typography
        variant="body2"
        component="span"
        sx={t => ({
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          display: 'block',
          overflow: 'hidden',
          color: activePacksWithCreatives === null ? t.palette.text.disabled : t.palette.text.primary,
        })}>
        {activePacksTag ? activePacksTag : `+ campaign_tag`}
      </Typography>
    </Button>
  </>;
};
