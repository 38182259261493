import { apiClient } from '../../../lib/api/apiClient';
import { useMutation } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';
import { isAxiosError } from 'axios';
import type { AdSetEntry } from './getAdSets';
import type { CreativePayloadEntry } from './types';
import type { CreativePackEntry } from '../types';

interface CreateFacebookAdParams {
  app?: string;
  platform?: string;
  ad_type?: string;
  call_to_action?: string;
  creatives: CreativePayloadEntry[] | CreativePackEntry[];
  ad_sets: AdSetEntry[] | null;
  hotjar?: boolean;
  conversion_domain?: string;
  ad_settings?: Record<string, unknown>;
  optional_ad_label?: string;
}

export interface CreateFacebookAdResponse {
  job_id: string;
}

const createFacebookAd = async (body: CreateFacebookAdParams): Promise<CreateFacebookAdResponse> => {
  const res = await apiClient.post<CreateFacebookAdResponse>(
    `/api/v1/facebook_manager/create_ad`,
    body,
  );

  if (isAxiosError(res)) {
    const error = new Error('Create FacebookAd Error');
    error.cause = {
      ...res.response?.data,
      status: res.response?.status,
    };
    return Promise.reject(error);
  }
  return res.data;
};

interface UseCreateFacebookAdOptions {
  config?: MutationConfig<typeof createFacebookAd>;
}

const useCreateFacebookAd = ({ config }: UseCreateFacebookAdOptions = {}) => {
  return useMutation({
    ...config,
    onSuccess: (...args) => {
      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    onError: (...args) => {
      if (config?.onError) {
        config.onError(...args);
      }
    },
    mutationFn: createFacebookAd,
  });
};

export { createFacebookAd, useCreateFacebookAd };