import { Box, Button, Tooltip } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

interface FormNavigation {
  onRestart: () => void,
  isJobStatusFinished?: boolean,
  isDisabledRestart: boolean,
  titleRestart?: string,
  onBack: () => void,
  isDisabledBack: boolean,
  titleBack?: string,
  onNext: () => void,
  isDisabledNext: boolean,
  titleNext?: string,
  childrenOnTheRightSide?: ReactNode,
  childrenOnTheLeftSide?: ReactNode,
}

const NextTooltip = ({ children }: { children: React.ReactNode }) => {
  const { formState } = useFormContext();
  const nextButtonTooltip = formState.errors?.packsWithCreatives?.message as string;
  return (
    <Tooltip title={nextButtonTooltip ? nextButtonTooltip : null} placement={'top'}>
      <Box>{children}</Box>
    </Tooltip>
  );
};

const FormNavigation: FC<FormNavigation> =
  ({
     onRestart,
     isDisabledRestart,
     titleRestart,
     onBack,
     isDisabledBack,
     titleBack,
     onNext,
     isDisabledNext,
     titleNext,
     isJobStatusFinished,
     childrenOnTheRightSide,
     childrenOnTheLeftSide,
   }) => {

    if (isJobStatusFinished) {
      return (
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 5,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            pt: 2,
            pb: 2,
          }}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'end', width: '100%', pt: 2 }}
          >
            <Button
              onClick={onRestart}
              variant="text"
              color="primary"
            >
              {titleRestart ?? 'Restart'}
            </Button>
            {childrenOnTheLeftSide}
          </Box>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 5,
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          pt: 2,
          pb: 2,
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'start', gap: 2, pt: 2 }}
        >
          <Button
            onClick={onRestart}
            variant="text"
            color="error"
            disabled={isDisabledRestart}
          >
            {titleRestart ?? 'Restart'}
          </Button>
          {childrenOnTheLeftSide}
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'start', gap: 2, pt: 2 }}
        >
          <Button
            onClick={onBack}
            disabled={isDisabledBack}
            variant="outlined"
            color="primary"
          >
            {titleBack ?? 'Back'}
          </Button>
          {childrenOnTheRightSide}
          <NextTooltip>
            <Button
              onClick={onNext}
              variant="contained"
              color="primary"
              disabled={isDisabledNext}
            >
              {titleNext ?? 'Next'}
            </Button>
          </NextTooltip>
        </Box>
      </Box>
    );
  };

export default FormNavigation;