import { useFormContext } from 'react-hook-form';
import {
  FormControlLabel,
  Menu,
  MenuItem,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import type { SelectProps } from '../../../components/common/Select';
import Select from '../../../components/common/Select';
import { isEmpty } from 'lodash-es';
import { useAdTypes } from '../api/getAdTypes';
import type { AccountEntry } from '../api/getAccounts';
import { useAccounts } from '../api/getAccounts';
import Autocomplete from '../../../components/common/Autocomplete';
import { useCallToActions } from '../api/getCallToActions';
import { useConversionDomain } from '../api/getConversionDomain';
import type { AdSettingEntry, AdSettingPageEntry, AdSettingTextEntry } from '../api/getAdSettings';
import useAdSettings from '../api/getAdSettings';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect } from 'react';
import adSettingsMapper from '../utils/adSettings.mapper';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import type { RowResultEntry } from '../api/types';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';
import type { CampaignEntry, RowStatus } from '../api/getCampaigns';
import type { PopupState as PopupStateT } from 'material-ui-popup-state/hooks';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import type { GridRenderCellParams } from '@mui/x-data-grid';
import type { AdSetEntry } from '../api/getAdSets';

type SelectAdTypes = Pick<SelectProps, 'handleChange'>
export const SelectAdTypes: FC<SelectAdTypes> = ({ handleChange }) => {
  const { watch, setValue } = useFormContext();
  const platform = watch('platform') as string;

  const { data, isLoading } = useAdTypes({
    params: { platform },
    config: {
      enabled: !!platform,
      select: (data) =>
        data.map((item) => ({
          value: item.code,
          label: item.name,
        })),
    },
  });
  return isLoading ? (
    <Skeleton
      height={40}
      sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
    />
  ) : (
    <Select
      name="adType"
      inputLabel="Ad type"
      disabled={isEmpty(data)}
      options={data ?? []}
      handleChange={handleChange}
      autoFocus
      confirmation={{
        title: 'Changing of Ad type will reset all packs and selected creatives',
        bodyText: 'Ad type is common for all the packs and it’s changing will reset all selected creatives',
        cancelButtonTitle: 'Cancel',
        submitButtonTitle: 'Change Ad Type',
        onSubmit: () => {
          setValue('packsWithCreatives', [{ tag: '', creatives: [] }]);
          setValue('selectedCreatives', []);
          setValue('activePacksWithCreatives', 0);
        },
      }}
    />
  );
};

export const SelectAdAccounts = () => {
  const { watch } = useFormContext();
  const app = watch('app') as string;
  const platform = watch('platform') as string;

  const dataMapper = (item: AccountEntry) => ({
    value: item.ad_account_id,
    label: item.name,
  });

  const { data, isError, error } = useAccounts({
    params: { platform, app },
    config: {
      select: (data) => data.map(dataMapper),
    },
  });

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Ad Accounts'}
        message={(error as {
          cause?: { error?: string }
        })?.cause?.error ?? 'An error occurred while Ad Accounts'} />
    );
  }

  return (
    <Autocomplete
      id="adAccounts"
      name="adAccounts"
      InputProps={{
        label: 'Ad Accounts',
      }}
      options={data ?? []}
    />
  );
};

export const SelectCallToActions = () => {
  const { watch } = useFormContext();
  const platform = watch('platform') as string;

  const { data, isLoading, isError, error } = useCallToActions({
    params: { platform },
    config: {
      enabled: !!platform,
      select: (data) =>
        data.map((item) => ({
          value: item.code,
          label: item.name,
        })),
    },
  });

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Call To Actions'}
        message={(error as { cause?: { error?: string } })?.cause?.error ?? 'Error occur'}
      />
    );
  }

  return isLoading ? (
    <Skeleton
      height={40}
      sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
    />
  ) : (
    <Select
      name="callToActions"
      inputLabel="Call To Actions"
      disabled={isEmpty(data)}
      options={data ?? []}
    />
  );
};

export const SelectConversationDomain = () => {
  const { watch } = useFormContext();
  const app = watch('app') as string;

  const { data, isError, error } = useConversionDomain({
    params: { app },
    config: {
      select: (data) =>
        data.map((domain) => ({
          value: domain,
          label: domain,
        })),
    },
  });

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Conversation domain'}
        message={(error as { cause?: { error?: string } })?.cause?.error ?? 'Error occur'}
      />
    );
  }

  return (
    <Select
      name="selectedConversionDomain"
      inputLabel="Conversation Domain"
      disabled={isEmpty(data)}
      options={data ?? []}
    />
  );
};

export const DuplicateStatusMenu = ({ formField }: { formField: 'selectedCampaigns' | 'selectedAdSets' }) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'duplicateStatusMenu' });
  const { watch, setValue } = useFormContext();
  const selectedRows = watch(formField) as (CampaignEntry | AdSetEntry)[];

  const onSetAllStatus = useCallback((popupState: PopupStateT, status: RowStatus) => {
    setValue(formField, selectedRows.map(c => ({ ...c, status })));
    popupState.close(); // Close the menu
  }, [setValue, formField, selectedRows]);

  return (
    <>
      <Button
        {...bindTrigger(popupState)}
        endIcon={<ArrowDropDownIcon />}
        color={'inherit'}
        variant={'text'}
        size={'small'}
        sx={{ textTransform: 'initial' }}>
        Duplicate status
      </Button>
      <Menu {...bindMenu(popupState)}>
        <MenuItem onClick={() => {
          onSetAllStatus(popupState, 'ACTIVE');
        }}>Set all as Active</MenuItem>
        <MenuItem onClick={() => {
          onSetAllStatus(popupState, 'PAUSED');
        }}>Set all as Paused</MenuItem>
      </Menu>
    </>
  );
};

export const CampaignStatusSwitcher = ({ cellParams }: { cellParams: GridRenderCellParams }) => {
  const currentId = (cellParams.row as CampaignEntry).id;
  const { watch, setValue } = useFormContext();
  const selectedCampaignsRows = watch('selectedCampaigns') as CampaignEntry[];
  const currentRow = selectedCampaignsRows?.find((r) => r.id === currentId);
  const isSelected = !!currentRow;
  const isChecked = currentRow?.status === 'ACTIVE';
  const labelText = isChecked ? 'Active' : 'Paused';

  const onChange = useCallback((_: ChangeEvent, checked: boolean) => {
    setValue('selectedCampaigns', selectedCampaignsRows.map(campaign => {
      if (campaign.id === currentId) {
        return ({ ...campaign, status: checked ? 'ACTIVE' : 'PAUSED' });
      }
      return campaign;
    }));
  }, [currentId, setValue, selectedCampaignsRows]);

  return (isSelected && (
      <FormControlLabel
        onClick={e => {
          e.stopPropagation();
        }}
        control={
          <Switch
            size={'small'}
            defaultChecked={isChecked}
            checked={isChecked}
            onChange={onChange}
            sx={{ mr: 1 }} />
        }
        label={labelText}
        sx={{ pl: 2 }}
      />
    )
  );
};

export const AdSetStatusSwitcher = ({ cellParams }: { cellParams: GridRenderCellParams }) => {
  const currentId = (cellParams.row as AdSetEntry).id;
  const { watch, setValue } = useFormContext();
  const selectedAdSetsRows = watch('selectedAdSets') as AdSetEntry[];
  const currentRow = selectedAdSetsRows?.find((r) => r.id === currentId);
  const isSelected = !!currentRow;
  const isChecked = currentRow?.status === 'ACTIVE';
  const labelText = isChecked ? 'Active' : 'Paused';

  const onChange = useCallback((_: ChangeEvent, checked: boolean) => {
    setValue('selectedAdSets', selectedAdSetsRows.map(adSet => {
      if (adSet.id === currentId) {
        return ({ ...adSet, status: checked ? 'ACTIVE' : 'PAUSED' });
      }
      return adSet;
    }));
  }, [currentId, setValue, selectedAdSetsRows]);

  return (isSelected && (
      <FormControlLabel
        onClick={e => {
          e.stopPropagation();
        }}
        control={
          <Switch
            size={'small'}
            defaultChecked={isChecked}
            checked={isChecked}
            onChange={onChange}
            sx={{ mr: 1 }} />
        }
        label={labelText}
        sx={{ pl: 2 }}
      />
    )
  );
};

export const SelectAdSettings = () => {
  const { watch, setValue } = useFormContext();
  const app = watch('app') as string;
  const platform = watch('platform') as string;
  const selectedAdSetsRows = watch('selectedAdSets') as AdSetEntry[];
  const adSets = selectedAdSetsRows
    ?.map(({ account_id, name, id, campaign_id }) => ({ account_id, name, id, campaign_id })) ?? [];

  const {
    data: adSettings,
    isError,
    error,
    isSuccess: isSuccessAdSettings,
    isLoading: isLoadingAdSetttings,
  } = useAdSettings({
    params: { app, platform, ad_sets: adSets },
    config: {
      select: adSettingsMapper,
    },
  });

  useEffect(() => {
    if (isSuccessAdSettings) {
      const selectedSettings = (adSettings as AdSettingEntry[]).reduce((acc, {
        name,
        pages,
        texts,
      }) => {
        const defaultPage = pages?.find((page: AdSettingPageEntry) => page.is_default);
        const defaultText = texts?.find((text: AdSettingTextEntry) => text.is_default);

        return {
          ...acc,
          [name]: {
            page_id: defaultPage ? defaultPage.id : '',
            text_id: defaultText ? defaultText.id : '',
          },
        };
      }, {});

      if (!isEmpty(selectedSettings)) {
        setValue(`selectedAdSettings`, selectedSettings);
      }
    }
  }, [adSettings, setValue, isSuccessAdSettings]);

  if (isLoadingAdSetttings) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: '500' }} gutterBottom>
          Ad Settings
        </Typography>
        <Skeleton
          height={40}
          width={'50%'}
          sx={{ transform: 'translateY(0)', borderRadius: '8px' }}
        />
      </Box>
    );
  }

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Ad Settings'}
        message={(error as { cause?: { error?: string } })?.cause?.error ?? 'Error occur'}
      />
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: '500' }} gutterBottom>
        Ad Settings
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {adSettings?.map((setting: AdSettingEntry, i: number) => (
          <>
            <Box key={`${setting.name}.${i}`} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant={'body2'}>{setting.name}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, gap: 3 }}>
                <Box sx={{ flex: 1 }}>
                  <Select
                    disabled={!setting?.pages?.length}
                    name={`selectedAdSettings.${setting.name}.page_id`}
                    options={setting?.pages?.map(s => ({ value: s.id, label: s.name })) ?? []}
                    inputLabel={'Page'} />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Select
                    disabled={!setting?.texts?.length}
                    sx={{ '.MuiSelect-select': { whiteSpace: 'wrap' } }}
                    name={`selectedAdSettings.${setting.name}.text_id`}
                    options={setting?.texts?.map(s => ({ value: s.id, label: s.name })) ?? []}
                    inputLabel={'Text'} />
                </Box>
              </Box>
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
};


export const JobResultTable = ({ rows }: { rows?: RowResultEntry[] }) => {
  if (!rows) return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="body1" sx={{ fontWeight: '500' }} gutterBottom>
        Job Result
      </Typography>
      <Typography sx={t => ({ fontSize: 16, color: t.palette.error.main, mb: 2 })} gutterBottom>
        An error occurred while fetching data
      </Typography>
    </Box>
  );

  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Ad Account</TableCell>
            <TableCell align="center">Adset</TableCell>
            <TableCell align="center">Creative</TableCell>
            <TableCell align="center">Result</TableCell>
            <TableCell align="center">Ad ID</TableCell>
            <TableCell align="center">Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: RowResultEntry) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.account_id}
              </TableCell>
              <TableCell align="left">{row.ad_set}</TableCell>
              <TableCell align="left">{row.creative}</TableCell>
              <TableCell align="center">
                {row.success ? '✅' : '❌'}
              </TableCell>
              <TableCell align="center">{row.ad_id}</TableCell>
              <TableCell align="left">{row.output}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};