import type { ComponentProps, FC } from 'react';
import { useCallback, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { SelectChangeEvent, SxProps, Theme } from '@mui/material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  Typography,
} from '@mui/material';
import { bindDialog, usePopupState } from 'material-ui-popup-state/hooks';

interface Option {
  value: string | number;
  label: string;
}

export interface SelectProps {
  name: string;
  options: Option[];
  label?: string;
  inputLabel?: string;
  required?: boolean;
  disabled?: boolean;
  handleChange?: (event: SelectChangeEvent<unknown>) => void;
  PaperProps?: object;
  sx?: SxProps<Theme>;
  restProps?: ComponentProps<typeof MuiSelect>;
  autoFocus?: boolean;
  confirmation?: {
    title?: string;
    bodyText?: string;
    cancelButtonTitle?: string;
    submitButtonTitle?: string
    onSubmit?: () => void
  };
}

const Select: FC<SelectProps> =
  ({
     name,
     options,
     label,
     inputLabel,
     required,
     disabled,
     handleChange,
     sx,
     PaperProps,
     autoFocus,
     confirmation,
     ...restProps
   }) => {
    const [fieldValue, setFieldValue] = useState('');
    const popupState = usePopupState({ variant: 'dialog', popupId: `ConfirmationDialog_${name}` });

    const { control, formState, trigger, setValue, watch } = useFormContext<FieldValues>();
    const currentValue = watch(name) as string;
    const error = formState.errors[name];
    const handleOpenConfirmation = useCallback((value: string) => {
      setFieldValue(value);
      popupState.open();
    }, [setFieldValue, popupState]);

    const handleApproveConfirmation = useCallback(() => {
      confirmation?.onSubmit?.();
      setValue(name, fieldValue);
      setFieldValue('');
      popupState.close();
    }, [setValue, setFieldValue, name, popupState, confirmation, fieldValue]);

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const { value, onChange } = field;
          return (
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
              {label && !inputLabel && (
                <Typography variant="subtitle2" mb={1}>
                  {label}
                  {required && '*'}
                </Typography>
              )}
              <FormControl sx={{ flex: 1 }}>
                {!label && inputLabel && (
                  <InputLabel id={`${inputLabel}_select`}>
                    {inputLabel}
                  </InputLabel>
                )}
                <MuiSelect
                  autoFocus={autoFocus}
                  labelId={inputLabel ? `${inputLabel}_select` : undefined}
                  {...field}
                  value={value ? String(value) : ''}
                  fullWidth
                  input={
                    <OutlinedInput
                      placeholder={inputLabel}
                      label={inputLabel ?? null}
                      sx={{ whiteSpace: 'normal' }}
                    />
                  }
                  MenuProps={{
                    PaperProps: {
                      ...PaperProps,
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  error={!!error}
                  disabled={disabled}
                  sx={{
                    ...sx,
                    ...(!value && {
                      '& .MuiSelect-nativeInput': {
                        top: 0,
                        pl: 1.7,
                        border: 'none',
                      },
                      '&.Mui-focused .MuiSelect-nativeInput': {
                        opacity: 1,
                      },
                    }),
                  }}

                  {...restProps}
                  variant="outlined"
                  label={label ?? inputLabel}
                  onChange={async (event) => {
                    if (confirmation && !!currentValue) {
                      handleOpenConfirmation(event.target.value);
                      return;
                    }
                    onChange(event);
                    await trigger(name);
                    handleChange && handleChange(event);
                  }}
                >
                  {options.map((o, i) => (
                    <MenuItem key={`${i}.${o.value}`} value={o.value} sx={{ wordBreak: 'break-all' }}>
                      {o.label}
                    </MenuItem>
                  ))}
                </MuiSelect>
                {error?.message && (
                  <Typography
                    variant="subtitle1"
                    fontSize={12}
                    sx={{ mt: '3px' }}
                    color="error"
                  >
                    {error.message as string}
                  </Typography>
                )}
                {confirmation && <Dialog
                  fullWidth
                  maxWidth="xs"
                  {...bindDialog(popupState)}
                  onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                      popupState.close();
                    }
                  }}
                >
                  <DialogTitle>{confirmation?.title ?? 'Changing'}</DialogTitle>
                  <DialogContent>
                    <Typography variant="body1">
                      {confirmation?.bodyText ?? 'Are you sure?'}
                    </Typography>
                  </DialogContent>
                  <DialogActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
                    <Button
                      onClick={() => {
                        popupState.close();
                      }}
                      variant="text"
                      color="error"
                    >
                      {confirmation?.cancelButtonTitle ?? 'Cancel'}
                    </Button>
                    <Button
                      onClick={handleApproveConfirmation}
                      variant="contained"
                    >
                      {confirmation?.submitButtonTitle ?? 'Change'}
                    </Button>
                  </DialogActions>
                </Dialog>}
              </FormControl>
            </Box>
          );
        }}
      />
    );
  };

export default Select;