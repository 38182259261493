import { useFormContext } from 'react-hook-form';
import useRowsCreatives from '../../creatives/hooks/useRowsCreatives';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import TextField from '../../../components/common/TextField';
import { DataGrid, type GridRenderCellParams } from '@mui/x-data-grid';
import { SelectAdTypes } from './FacebookFormComponents';
import { AddPackTagButton, PacksWithCreatives } from './PacksComponents';
import Checkbox from '../../../components/common/Checkbox';
import type { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';
import FormContainer from '../../../components/layout/FormContainer';
import type { PackEntry } from '../types';
import type { CreativeEntry } from 'types/shared';
import { useAdTypes } from '../api/getAdTypes';
import { isEmpty } from 'lodash-es';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const CREATIVES_LIMIT = 30;

const CreativesForm: FC = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();
  const { rows, isLoading, isError, error } = useRowsCreatives({
    params: {
      network: 'Facebook',
    },
  });

  const adType = watch('adType') as string;
  const isDLO = adType?.toLowerCase() === 'dlo';
  const platform = watch('platform') as string;
  const selectedCreatives = watch('selectedCreatives') as string[];
  const isCreativesFieldError = errors.selectedCreatives?.message as string | undefined;
  const isPackFieldError = errors.packsWithCreatives?.message as string | undefined;
  const selectedOnly = watch('selectedCreativesOnly') as string[];
  const packsWithCreatives = watch('packsWithCreatives') as PackEntry[];
  const activePacksWithCreatives = watch('activePacksWithCreatives') as number;

  const marketingProcess = watch('marketingProcess') as string;
  const isMarketingProcessInbox = marketingProcess?.includes('inbox');

  const { data: adTypes } = useAdTypes({
    params: { platform },
    config: {
      enabled: !!platform,
    },
  });

  const COLUMNS = useMemo(() => {
    const currentAdTypeValue = adTypes?.find(i => i.code === adType)?.name ?? adType;

    return ([
      { field: 'creative_id', headerName: 'Creative ID', width: 120 },
      {
        field: 'name',
        headerName: 'Name',
        width: 450,
        renderCell: (params: GridRenderCellParams<CreativeEntry>) => {
          const isSelectable = isDLO && !isEmpty(params.row.locales) || !isDLO;
          const tooltipMessage = !isSelectable ? `Can’t be used for ${currentAdTypeValue} without localizations` : null;

          return <>
            <Tooltip title={tooltipMessage} arrow placement={'top'}>
              <Box
                sx={{ display: 'flex', gap: .25, height: '100%', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography variant={'body2'}>{params.row.name}</Typography>
                <Typography
                  variant={'caption'}
                  sx={t => ({ color: t.palette.text.disabled })}>{isSelectable && !isEmpty(params.row.locales) ? `Loc: ${params.row.locales?.join(', ')}` : 'No localizations'}</Typography>
              </Box>
            </Tooltip>
          </>;
        },
      },
      { field: 'app', headerName: 'App', width: 120 },
      { field: 'creative_type', headerName: 'Type', width: 120 },
      { field: 'created', headerName: 'Created', width: 120 },
    ]);
  }, [adType, adTypes, isDLO]);

  const filteredRows = useMemo(() => {
    return selectedOnly
      ? rows?.filter(row => selectedCreatives.includes(row.id))
      : rows;
  }, [rows, selectedCreatives, selectedOnly]);

  const onSelectionModelChange = useCallback(
    (selectedRows: GridRowSelectionModel) => {
      const parsedSelectedRows = selectedRows.slice(0, CREATIVES_LIMIT);

      setValue('packsWithCreatives',
        [...packsWithCreatives].map((pack, index) =>
          index === activePacksWithCreatives
            ? { ...pack, creatives: parsedSelectedRows.map(String) }
            : pack,
        ),
      );
      setValue('selectedCreatives', parsedSelectedRows);
    },
    [packsWithCreatives, activePacksWithCreatives, setValue],
  );

  useEffect(() => {
    void trigger('selectedCreatives');
    // void trigger('adType');
    void trigger('packsWithCreatives');
  }, [trigger, selectedCreatives]);

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Choose Creatives'}
        message={(error as {
          cause?: { error?: string }
        })?.cause?.error ?? 'An error occurred while fetching creatives'} />
    );
  }

  return (
    <FormContainer title={adType ? 'Choose Creatives' : 'Select Ad type'} rightSideElement={<SelectAdTypes />}
                   error={[isCreativesFieldError ?? '', isPackFieldError ?? '']}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%' }}>
          <PacksWithCreatives />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2, width: '100%' }}>
          <Box sx={{
            display: 'flex',
            gap: 2,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'nowrap',
          }}>
            <TextField
              name={'filterCreativeByName'}
              placeholder={'Search by name'}
            />
            <Checkbox name={'selectedCreativesOnly'} label={'Selected only'}
                      controlLabelSx={{ whiteSpace: 'nowrap' }} />
          </Box>
          {!isMarketingProcessInbox && <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'start',
            gap: 2,
          }}>
            <Box sx={{
              display: 'flex',
              minHeight: 32,
              mt: 0.5,
            }}>
              <AddPackTagButton />
            </Box>
          </Box>}
        </Box>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box style={{ height: 380, width: '100%' }}>
            <DataGrid
              rows={filteredRows ?? []}
              columns={COLUMNS}
              localeText={{ noRowsLabel: !adType ? 'Select Ad type to add creatives' : '' }}
              checkboxSelection
              rowSelectionModel={selectedCreatives ?? []}
              loading={isLoading}
              onRowSelectionModelChange={onSelectionModelChange}
              keepNonExistentRowsSelected
              hideFooterPagination
              getRowId={({ id }) => id} // Each row must have a unique identifier
              isRowSelectable={(params) => {
                const isDLOLocalesEmpty = isDLO && !isEmpty(params.row.locales); // for DLO if there is no locales, the creative is not available for selection.
                const isUpToLimit = (selectedCreatives.length < CREATIVES_LIMIT || selectedCreatives.includes(params.row.id));
                return isDLOLocalesEmpty || !isDLO || isUpToLimit;
              }}
              slots={{
                footer: () => selectedCreatives.length ? <Box sx={t => ({
                  p: 2,
                  height: 54,
                  minHeight: 54,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  borderTop: `1px solid ${t.palette.divider}`,
                  cursor: 'default',
                })}>
                  {selectedCreatives.length === CREATIVES_LIMIT ? (
                    <>
                      <InfoIcon color={'primary'} sx={{ fontSize: 21 }} />
                      <Typography variant={'body2'}>{`Up to ${CREATIVES_LIMIT} creatives can be selected`}</Typography>
                    </>
                  ) : <Typography variant={'body2'}>{selectedCreatives.length} rows selected</Typography>}
                </Box> : null,
              }}
            />
          </Box>
        </Box>
      </Box>
    </FormContainer>
  );
};

export default CreativesForm;