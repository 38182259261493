import { useFormContext } from 'react-hook-form';
import type { CampaignEntry } from '../api/getCampaigns';
import { useCampaigns } from '../api/getCampaigns';
import { useCallback, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import type { GridColumnHeaderParams, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '../../../components/common/CircularProgress';
import SuspenseErrorFallback from '../../../components/common/SuspenseErrorFallback';
import FormContainer from '../../../components/layout/FormContainer';
import type { MarketingProcess } from '../../../types/shared';
import TextField from '../../../components/common/TextField';
import { CampaignStatusSwitcher, DuplicateStatusMenu } from './FacebookFormComponents';
import { CAMPAIGN_NAME_FILTER } from '../constants';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const CAMPAIGNS_LIMIT = 20;

const CampaignsForm = () => {
  const { watch, setValue, trigger, formState: { errors } } = useFormContext();
  const facebookAccountId = watch('adAccounts') as string[];
  const selectedCampaignsRows = watch('selectedCampaigns') as CampaignEntry[];
  const selectedCampaigns = selectedCampaignsRows?.map(c => c.id) ?? [];
  const isCampaignFieldError = errors.selectedCampaigns?.message as string | undefined;
  const marketingProcess = watch('marketingProcess') as MarketingProcess;
  const filterByName = watch('filterCampaignsByName') as string;
  const isMarketingProcessInbox = CAMPAIGN_NAME_FILTER[marketingProcess].includes('inbox');

  const COLUMNS = [
    { field: 'id', headerName: 'Campaign ID', width: 160 },
    { field: 'name', headerName: 'Campaign Name', width: 750 },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams<any>) => {
        if (!isMarketingProcessInbox) return <DuplicateStatusMenu formField={'selectedCampaigns'} />;

        return params.colDef.headerName;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (!isMarketingProcessInbox) return <CampaignStatusSwitcher cellParams={params} />;

        return (params.row as CampaignEntry).status;
      },
    },
  ];

  const { data: rows, isLoading, isPending, isError, error } = useCampaigns({
    params: { facebookAccountId, campaignNameFilter: CAMPAIGN_NAME_FILTER[marketingProcess] ?? '' },
  });

  const filteredRows = useMemo(() =>
    rows?.filter(r =>
      !filterByName ||
      r.name.toLowerCase().includes(filterByName.toLowerCase()) ||
      selectedCampaigns.includes(r.id), // retain all previously selected campaigns in a list
    ), [selectedCampaigns, rows, filterByName]);

  const handleSelectionMode = useCallback(
    (selectedRows: GridRowSelectionModel) => {
      const parsedSelectedRows = selectedRows.slice(0, CAMPAIGNS_LIMIT);
      const newSelectedCampaigns = rows?.filter(({ id }) => parsedSelectedRows?.includes(id)).map(c => ({
        ...c,
        status: selectedCampaignsRows?.find(s => s.id === c.id)?.status ?? c.status, // retain last updated status or return current value
      }));
      setValue('selectedCampaigns', newSelectedCampaigns);
    },
    [rows, setValue, selectedCampaignsRows],
  );

  useEffect(() => {
    void trigger('selectedCampaigns');
  }, [trigger, selectedCampaignsRows]);

  if (isPending) {
    return <CircularProgress title={'Campaigns are loading...'} />; // Optional loading state
  }

  if (isError) {
    return (
      <SuspenseErrorFallback
        title={'Choose Campaigns'}
        message={(error as {
          cause?: { error?: string }
        })?.cause?.error ?? 'An error occurred while fetching campaigns'} />
    );
  }

  if (!rows?.length) {
    return <Typography>No campaigns found.</Typography>; // Handle no data case
  }

  return (
    <FormContainer
      title={'Choose campaigns'}
      error={isCampaignFieldError}
      sx={{ flexDirection: 'column', gap: 2 }}>
      <TextField placeholder={'Search by name'} name={'filterCampaignsByName'} />
      {!isPending && rows?.length && <Box style={{ height: 380, width: '100%' }}>
        <DataGrid
          rows={filteredRows ?? []}
          columns={COLUMNS}
          checkboxSelection
          rowSelectionModel={selectedCampaigns}
          loading={isLoading}
          onRowSelectionModelChange={handleSelectionMode}
          hideFooterPagination
          disableColumnMenu
          getRowId={({ id }) => id} // Each row must have a unique identifier
          isRowSelectable={(params) => (selectedCampaigns.length < CAMPAIGNS_LIMIT || selectedCampaigns.includes(params.row.id))}
          slots={{
            footer: () => selectedCampaigns.length ? <Box sx={t => ({
              p: 2,
              height: 54,
              minHeight: 54,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              borderTop: `1px solid ${t.palette.divider}`,
              cursor: 'default',
            })}>
              {selectedCampaigns.length === CAMPAIGNS_LIMIT ? (
                <>
                  <InfoIcon color={'primary'} sx={{ fontSize: 21 }} />
                  <Typography variant={'body2'}>{`Up to ${CAMPAIGNS_LIMIT} campaigns can be selected`}</Typography>
                </>
              ) : <Typography variant={'body2'}>{selectedCampaigns.length} rows selected</Typography>}
            </Box> : null,
          }}
        />
      </Box>}
    </FormContainer>
  );
};

export default CampaignsForm;