import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { bindDialog, usePopupState } from 'material-ui-popup-state/hooks';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import useRouteBlocker from '../../hooks/useBlocker';

const ConfirmationDialog: FC = () => {
  const navigate = useNavigate();
  const { formState: { isDirty } } = useFormContext();
  const popupState = usePopupState({ variant: 'dialog', popupId: 'ConfirmationDialog' });

  const { nextPathname, onBlockRoute, onUnblockRoute, routeBlocker } = useRouteBlocker({ callback: popupState.open });

  const handleStayOnPage = useCallback(() => {
    popupState.close();
  }, [popupState]);

  const handleLeavePage = useCallback(() => {
    close();
    if (routeBlocker.state === 'blocked' && routeBlocker.proceed) {
      routeBlocker.proceed();
    }
    onUnblockRoute();
    !routeBlocker.proceed && navigate(`/${nextPathname}`, { replace: true });
  }, [nextPathname, routeBlocker, navigate, onUnblockRoute]);

  useEffect(() => {
    isDirty ? onBlockRoute() : onUnblockRoute(); // Block navigation if form is dirty
  }, [isDirty, onBlockRoute, onUnblockRoute]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      {...bindDialog(popupState)}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleStayOnPage();
        }
      }}
    >
      <DialogTitle>Leave form</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          <Typography component={'span'} sx={{ fontWeight: 500 }}>Your changes will not be saved. </Typography>
          Are you sure you want to leave this form?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
        <Button
          onClick={handleStayOnPage}
          variant="text"
          color="error"
        >
          Cancel
        </Button>
        <Button
          onClick={handleLeavePage}
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;